import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import CommercialPropertiesEdit from "@components/commercial-properties/Edit"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "commercial-properties.edit"
}

const CommercialPropertiesEditPage = (props) => (
  <>
    <Seo title="Edit Commercial Property" />
    <div className="main-wrapper">
      <Sidebar menuActive="commercial-properties" 
        action="Edit"/>
      <CommercialPropertiesEdit 
        menu="Edit Commercial Properties" 
        parentPath="commercial-properties" 
        pageType="Edit"
        additionalParams={AdditionalParams}/>
    </div>
  </>
)

export default CommercialPropertiesEditPage;
