import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Alert } from "react-bootstrap";
import {
  HandleChange, GetAllItems, CheckFieldValidity, HandleAttachmentField,
  ClearAttachmentField
} from "../../utility/Form";
import CommonEditItemComp from "../common/Edit";
import Select from 'react-select';
import { set, isEmpty, get, find, sortBy } from "lodash";
import DatePicker from 'react-date-picker';
import ModalPopUp from "../common/modal/Modal";
import StatcTable from "../common/StaticTable";
import FormFooter from "../common/FormFooter";
import TextEditor from "../text-editor";
import CurrencyJson from "../../config/currency.json";
import CurrencyFormat from 'react-currency-format';

const EditFormControls = (props) => {
  //Default fields values
  const [DefaultFields, SetDefaultFields] = useState({
    amenities: [],
    building_manager: null,
    currency: {},
    rent: {},
    service_charge_currency: {},
    business_rate_currency: {},
    price: null,
    latitude: null,
    longitude: null
  });

  const [highLightFeatures, setHighLightFeatures] = useState([""]);
  const [uploadedData, setUploadedData] = useState({});
  const [allUploadedItems, setAllUploadedItems] = useState([]);
  const [buildingManagers, setBuildingManagers] = useState([]);
  const [buildingManager, setBuildingManager] = useState([]);
  const [dateValue, setDateValue] = useState()
  const [agentNotes, setAgentNotes] = useState([""]);
  const [floorPlanUrl, setFloorPlanUrl] = useState("");
  const [status, setStatus] = useState("");
  const [building, setBuilding] = useState("");
  const [floorAreaType, setFloorAreaType] = useState({});
  const [priceQualifier, setPriceQualifier] = useState({});
  const [currencyOption, setCurrencyOption] = useState({});
  const [selFacilities, setSelFacilities] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [currencyPricePerSqftOption, setCurrencyPricePerSqftOption] = useState();
  const [currencyServiceChargeOption, setCurrencyServiceChargeOption] = useState();
  const [currencyBusinessRateOption, setCurrencyBusinessRateOption] = useState();

  const GetBuildingManagers = async () => {
    try {
      const { data } = await GetAllItems('building-managers.getAll', { query: "_limit=-1" });
      let Managers = data.results.map(manager => {
        return { value: manager.id, label: manager.name, isDisabled: !manager.published_at }
      });
      Managers.splice(0, 0, { value: null, label: "Select option" })
      setBuildingManagers(Managers)
    } catch (error) {
      console.log('AddFormControls GetBuildingManagers error', error);
    }
  };

  //Get facilities
  const GetFecilities = async () => {
    try {
      const { data } = await GetAllItems('facilities.getAll', { query: "_limit=-1&_sort=facility:ASC" });
      let Facilities = data.results.map(facility => { return { value: facility.id, label: facility.facility } });
      setFacilities(Facilities)
    } catch (error) {
      console.log('AddFormControls GetBuildingManagers error', error);
    }
  };

  //Get settings
  const GetSettings = async () => {
    try {
      const { data } = await GetAllItems('settings.getAll', { query: "_limit=-1" });
      setCurrencyOption(data?.results[0]?.currency)
      set(DefaultFields, 'currency', data?.results[0]?.currency);
    } catch (error) {
      console.log('AddFormControls GetBuildingManagers error', error);
    }
  };

  useEffect(() => {
    GetBuildingManagers();
    GetSettings();
    GetFecilities();
  }, []);

  useEffect(() => {
    if (!isEmpty(props.editItem.amenities)) {
      const SelAmenities =  props.editItem.amenities.map(facility => {
        return { value: facility.id, label: facility.facility }
      }) || [];
      HandleMultiSelect(SelAmenities, 'amenities', true);
    }

    props?.editItem?.special && setHighLightFeatures(props.editItem.special || [""])
    props?.editItem?.agent_notes && setAgentNotes(props.editItem.agent_notes || [""])
    props?.editItem?.available_from && setDateValue(new Date(props.editItem.available_from))
    props?.editItem?.building_manager && setBuildingManager(
      { value: props.editItem.building_manager.id, label: props.editItem.building_manager.name, isDisabled: !props.editItem.building_manager.published_at })
    set(DefaultFields, `building_manager`, props?.editItem?.building_manager?.id || null);
    props?.editItem?.status && setStatus(
      { value: props.editItem.status, label: props.editItem.status });
    props?.editItem?.building && setBuilding(
      { value: props.editItem.building, label: props.editItem.building })
    props?.editItem?.images && setAllUploadedItems(props.editItem.images)
    props?.editItem?.floorplans?.url && setFloorPlanUrl(props?.editItem?.floorplans?.url);
    let checkboxValuesTemp = {
      'is_enabled': get(props, 'editItem.is_enabled'),
      'is_show_on_website': get(props, 'editItem.is_show_on_website'),
      'under_offer': get(props, 'editItem.under_offer'),
      'exclusive_of_vat': get(props, 'editItem.exclusive_of_vat'),
    };
    setCheckboxValues(checkboxValuesTemp);
    props?.editItem?.floorarea_type && setFloorAreaType({
      value: props.editItem.floorarea_type, label: props.editItem.floorarea_type
    })
    if (props?.editItem?.price) {
      setPrice(props.editItem.price);
      set(DefaultFields, 'price', props.editItem.price);
    }
    props?.editItem?.price_qualifier && setPriceQualifier({
      value: props.editItem.price_qualifier, label: props.editItem.price_qualifier
    })
    props?.editItem?.currency && setCurrencyOption(props?.editItem?.currency)
    set(DefaultFields, 'currency', props?.editItem?.currency || {});
    props?.editItem?.rent?.price_per_sqft_currency && setCurrencyPricePerSqftOption(props?.editItem?.rent?.price_per_sqft_currency)
    set(DefaultFields, 'rent.price_per_sqft_currency', props?.editItem?.currency || {});
    props?.editItem?.service_charge_currency && setCurrencyServiceChargeOption(props?.editItem?.service_charge_currency)
    set(DefaultFields, 'service_charge_currency', props?.editItem?.currency || {});
    props?.editItem?.business_rate_currency && setCurrencyBusinessRateOption(props?.editItem?.business_rate_currency)
    set(DefaultFields, 'business_rate_currency', props?.editItem?.currency || {});

    //Set medias
    set(DefaultFields, 'images', props.editItem.images || []);
    set(DefaultFields, 'documents', props.editItem.documents || []);
    set(DefaultFields, 'floorplans', props.editItem.floorplans || []);
  }, [props.editItem]);

  useEffect(() => {
    let UploadedItems = [...allUploadedItems].filter(item => !isEmpty(item));
    if (uploadedData.isUpdate || uploadedData.isDelete) {
      let itemsByField = [], itemsByOtherFields = [];
      UploadedItems.forEach(element => {
        if (element.field === uploadedData.field)
          return itemsByField.push(element);
        itemsByOtherFields.push(element);
      });
      if (uploadedData.isUpdate) {
        itemsByField = itemsByField.map((item, index) => {
          if (index === uploadedData.index) {
            const ItemMerged = { ...item, ...uploadedData };
            delete ItemMerged.isUpdate;
            return ItemMerged;
          }
          return item;
        });
      } else if (uploadedData.isDelete) {
        itemsByField = itemsByField.filter((item, index) => {
          if (index === uploadedData.index) {
            return false;
          }
          return item;
        });
      }
      UploadedItems = itemsByField.concat(itemsByOtherFields);
    } else if (!isEmpty(uploadedData) && !isEmpty(uploadedData.items)) {
      //concat 2 arrays
      UploadedItems = UploadedItems.concat(uploadedData.items);
    }
    UploadedItems = sortBy(UploadedItems, ['field', 'order']);
    set(DefaultFields, `${uploadedData.field}`, [...UploadedItems]
      .filter(item => (item.field === uploadedData.field)));
    setAllUploadedItems([...UploadedItems].filter(item => !isEmpty(item)));
  }, [uploadedData]);

  const GetUploadedUrl = async (event) => {
    try {
      const UploadedResult = await HandleAttachmentField(event, { ...props });
      set(DefaultFields, `${UploadedResult['field'] || 'media'}`, { url: UploadedResult.url, id: UploadedResult.id })
      setFloorPlanUrl(UploadedResult.url);
    } catch (error) {
      console.log('GetUploadedUrl error', error, event, props)
    }
  }

  const addHighLightFeatures = (index) => {
    if (highLightFeatures[index]) {
      setHighLightFeatures([...highLightFeatures, ""]);
    } else {
      alert('Please fill!');
    }
  }

  const removeHighLightFeatures = (index) => {
    let newHighLightFeatures = [...highLightFeatures]
    newHighLightFeatures.splice(index, 1);
    setHighLightFeatures(newHighLightFeatures);
  }

  const handleHighLightFeaturesChange = (event, index) => {
    let newHighLightFeatures = [...highLightFeatures];
    newHighLightFeatures[index] = event.target.value;
    setHighLightFeatures(newHighLightFeatures);
  }

  const handleHighLightFeaturesKP = (event, index) => {
    if (event.charCode === 13) {
      event.preventDefault();
      addHighLightFeatures(index);
    }
  }

  const addAgentNotes = (index) => {
    if (agentNotes[index]) {
      setAgentNotes([...agentNotes, ""]);
    } else {
      alert('Please fill!');
    }
  }

  const removeAgentNotes = (index) => {
    let newAgentNotes = [...agentNotes];
    newAgentNotes.splice(index, 1);
    setAgentNotes(newAgentNotes);
  }

  const handleAgentNotesChange = (event, index) => {
    let newAgentNotes = [...agentNotes];
    newAgentNotes[index] = event.target.value;
    setAgentNotes(newAgentNotes);
  }

  const handleAgentNotesKP = (event, index) => {
    if (event.charCode === 13) {
      event.preventDefault();
      addAgentNotes(index);
    }
  }

  const getUploadedData = (uploadedDataFromModal) => {
    setUploadedData(uploadedDataFromModal);
  }

  const [showModalImages, setShowModalImages] = useState(false);
  const showModalImagesFunc = () => {
    setShowModalImages(true);
  }
  const setShowModalImagesFunc = () => {
    setShowModalImages(false);
  }

  const [formError, setFormError] = useState({});
  const [isFormSaveTriggered, setIsFormSaveTriggered] = useState(false);
  const CheckFormFieldValidity = (event) => {
    const FieldError = CheckFieldValidity(event);
    setFormError({ ...formError, ...FieldError });
  }

  const HandleChangeFunc = (event, name) => {
    HandleChange(event);
    CheckFormFieldValidity(event);
    if (event?.target?.name === 'latitude') 
      set(DefaultFields, 'latitude', (event?.target?.value || null));
    if (event?.target?.name === 'longitude') 
      set(DefaultFields, 'longitude', (event?.target?.value || null));
  }

  const [price, setPrice] = useState();
  const DecodePriceValue = (priceObj) => {
    set(DefaultFields, 'price', priceObj.floatValue);
    setPrice(priceObj.floatValue);
  }

  const FormSubmitFunc = async (event, additionalParams = {}) => {
    try {
      setIsFormSaveTriggered(true);
      const Result = await props.HandleSubmitFunc(event, {
        formId: 'edit-commecial-property-form',
        ...additionalParams,
        fields: { ...DefaultFields }
      });
      let selectBoxErrorObjTemp = {};
      if (get(Result, 'formSubmit.hasError') || isEmpty(selectBoxErrorObjTemp)) {
        setFormError(Result.formSubmit.error);
      } else {
        setIsFormSaveTriggered(false);
      }
    } catch (error) {
      console.log('FormSubmitFunc error', error);
    }
  }

  const SaveCurrentTab = (event) => {
    FormSubmitFunc(event, {
      isSubmit: true,
      enableValidation: true
    });
  }

  const [checkboxValues, setCheckboxValues] = useState({});
  const HandleCheckBoxValue = (event) => {
    let checkBoxValueTemp = { [event.target.name]: event.target.checked };
    if ((event.target.name === 'is_enabled') && (!checkBoxValueTemp['is_enabled'])) {
      checkBoxValueTemp['is_show_on_website'] = false;
    }
    setCheckboxValues({ ...checkboxValues, ...checkBoxValueTemp });
  }

  const HandleMultiSelect = (event, name, copyInAddParam) => {
    if (copyInAddParam) {
      const SelectedOptions = event.map(option => option.value)
      set(DefaultFields, `${name}`, SelectedOptions);
    }
    switch (name) {
      case 'building':
        setBuilding(event);
        break;
      case 'building_manager':
        setBuildingManager(event);
        set(DefaultFields, `building_manager`, event.value);
        break;
      case 'floorarea_type':
        setFloorAreaType(event);
        break;
      case 'price_qualifier':
        setPriceQualifier(event);
        break;
      case 'status':
        setStatus(event);
        break;
      case 'currency':
        set(DefaultFields, `currency`, event);
        setCurrencyOption(event);
        break;
      case 'rent.price_per_sqft_currency':
        set(DefaultFields, `rent.price_per_sqft_currency`, event);
        setCurrencyPricePerSqftOption(event);
        break;
      case 'service_charge_currency':
        set(DefaultFields, `service_charge_currency`, event);
        setCurrencyServiceChargeOption(event);
        break;
      case 'business_rate_currency':
        set(DefaultFields, `business_rate_currency`, event);
        setCurrencyBusinessRateOption(event);
      case 'amenities':
        setSelFacilities(event);
        break;
      default:
        break;
    }
  }

  const editorChange = (content, editor, fieldName) => { };

  return (
    <>
      <Form id="edit-commecial-property-form" noValidate onSubmit={(event) => SaveCurrentTab(event)}>
        {
          isFormSaveTriggered && ((find(formError, function (error) {
            return (error.hasError)
          }))) && <Alert key="danger" variant="danger">
            Highlighted fields are required
          </Alert>
        }
        <input type="hidden" name="department" value="commercial" />
        <input type="hidden" name="search_type" value="lettings" />
        <input type="hidden" name="status" value="To Rent" />
        <Row>
          <Col lg={12} className="d-flex justify-content-end">
            <div className="text-end ms-3">
              <Button type="submit" className="btn-primary-dashboard">Save</Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Number / Name *</label>
              <input type="text" className={`form-control${isFormSaveTriggered && formError?.building_name?.hasError ? ' pu-has-control-error' : ''}`}
                placeholder="Enter number/name here"
                name="building_name"
                required={true}
                defaultValue={props.editItem.building_name}
                onChange={(event) => HandleChangeFunc(event)} />
            </div>
          </Col>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Address 1</label>
              <input type="text" className="form-control"
                name="address_line1"
                placeholder="Enter address here"
                onChange={(event) => HandleChangeFunc(event)}
                defaultValue={props.editItem.address_line1}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Address 2</label>
              <input type="text" className="form-control"
                name="address_line2"
                placeholder="Enter address here"
                defaultValue={props.editItem.address_line2} />
            </div>
          </Col>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Address 3</label>
              <input type="text" className="form-control"
                name="address_line3"
                placeholder="Enter address here"
                defaultValue={props.editItem.address_line3} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Town / City </label>
              <input type="text" className="form-control"
                name="town"
                placeholder="Enter town here"
                onChange={(event) => HandleChangeFunc(event)}
                defaultValue={props.editItem.town}
              />
            </div>
          </Col>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>County </label>
              <input type="text" className="form-control"
                name="county"
                placeholder="Enter county here"
                onChange={(event) => HandleChangeFunc(event)}
                defaultValue={props.editItem.county}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Postcode</label>
              <input type="text" className="form-control"
                name="post_code"
                defaultValue={props.editItem.post_code}
                placeholder="Enter post code here" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Latitude </label>
              <input type="text" className="form-control"
                name="latitude"
                placeholder="Enter latitude here"
                onChange={(event) => HandleChangeFunc(event)}
                defaultValue={props.editItem.latitude}
              />
            </div>
          </Col>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Longitude </label>
              <input type="text" className="form-control"
                name="longitude"
                placeholder="Enter longitude here"
                onChange={(event) => HandleChangeFunc(event)}
                defaultValue={props.editItem.longitude}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Type</label>
              <div className="custom-selectbox-form selectbox-dropdown">
                <Select
                  name="building"
                  options={[
                    { value: "", label: "Select option" },
                    { value: "Office", label: "Office" },
                    { value: "Retail", label: "Retail" },
                    { value: "Commercial", label: "Commercial" }
                  ]}
                  placeholder="Please select type"
                  classNamePrefix={"select-opt"}
                  isSearchable={false}
                  value={building}
                  onChange={(event) => HandleMultiSelect(event, 'building')}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="mb-4">
            <ul className="list-inline m-0 mt-4">
              <li className="list-inline-item">
                <div className="form-check dashboard-checkbox me-3">
                  <input className="form-check-input" type="checkbox"
                    value={true}
                    id="enabled"
                    name="is_enabled"
                    onChange={event => HandleCheckBoxValue(event)}
                    checked={checkboxValues?.['is_enabled']} />
                  <label className="form-check-label" for="enabled">Enabled</label>
                </div>
              </li>
              {
                checkboxValues?.['is_enabled'] && <li className="list-inline-item">
                  <div className="form-check dashboard-checkbox me-3">
                    <input className="form-check-input" type="checkbox"
                      value={true}
                      id="website"
                      name="is_show_on_website"
                      onChange={event => HandleCheckBoxValue(event)}
                      checked={checkboxValues?.['is_show_on_website']} />
                    <label className="form-check-label" for="website">Show on Website</label>
                  </div>
                </li>
              }
              <li className="list-inline-item">
                <div className="form-check dashboard-checkbox me-3">
                  <input className="form-check-input" type="checkbox"
                    value={true}
                    id="offer"
                    name="under_offer"
                    onChange={event => HandleCheckBoxValue(event)}
                    checked={checkboxValues?.['under_offer']} />
                  <label className="form-check-label" for="offer">Under offer</label>
                </div>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Rental status</label>
              <div className="custom-selectbox-form selectbox-dropdown">
                <Select
                  name="status"
                  options={[
                    { value: "", label: "Select option" },
                    { value: "Available", label: "Available" },
                    { value: "Unavailable", label: "Unavailable" },
                  ]}
                  placeholder="Please select rental status"
                  classNamePrefix={"select-opt"}
                  isSearchable={false}
                  value={status}
                  onChange={(event) => HandleMultiSelect(event, 'status')}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Available from</label>
              <div className="form-datepicker">
                <DatePicker
                  format="dd-MM-yyyy"
                  name="available_from"
                  onChange={setDateValue}
                  value={dateValue}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Building Manager</label>
              <div className="custom-selectbox-form selectbox-dropdown">
                <Select
                  name="building_manager"
                  options={buildingManagers}
                  placeholder="Please select building manager"
                  classNamePrefix={"select-opt"}
                  isSearchable={false}
                  value={buildingManager}
                  onChange={(event) => HandleMultiSelect(event, 'building_manager')}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="mb-4">
            <div className="form-wrapper">
              <label>Short Description</label>
              <TextEditor name="introtext" defaultValue={get(props, 'editItem.introtext')}
                additionalParams={props.additionalParams}
                editorChange={(content, editor) => editorChange(content, editor, 'introtext')} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="mb-4">
            <div className="form-wrapper">
              <label>Description</label>
              <TextEditor name="description"
                defaultValue={get(props, 'editItem.description')}
                additionalParams={props.additionalParams}
                editorChange={(content, editor) => editorChange(content, editor, 'description')} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Floor</label>
              <input type="text" className="form-control"
                name="floor"
                defaultValue={props.editItem.floor}
                placeholder="Enter floor here" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Size</label>
              <ul className="list-inline regions-wrapper col-2-width m-0">
                <li className="list-inline-item">
                  <input type="number" className="form-control"
                    name="floorarea_min"
                    defaultValue={props.editItem.floorarea_min}
                    placeholder="Enter size here" />
                </li>
                <li className="list-inline-item">
                  <div className="custom-selectbox-form selectbox-dropdown">
                    <Select
                      name="floorarea_type"
                      options={[
                        { value: "", label: "Select option" },
                        { value: "Sq feet", label: "Sq feet" },
                        { value: "Sq meters", label: "Sq meters" },
                        { value: "Sq yards", label: "Sq yards" },
                        { value: "Hectares", label: "Hectares" },
                        { value: "Acres", label: "Acres" },
                      ]}
                      placeholder="Please select type"
                      classNamePrefix={"select-opt"}
                      isSearchable={false}
                      value={floorAreaType}
                      onChange={(event) => HandleMultiSelect(event, 'floorarea_type')}
                    />
                  </div>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Usage Type</label>
              <input type="text" className="form-control"
                name="usage_type"
                defaultValue={props.editItem.usage_type}
                placeholder="Enter usage type here" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <Row>
                <Col lg={3}>
                  <label>Currency</label>
                  <div className="custom-selectbox-form selectbox-dropdown">
                    <Select
                      name="currency"
                      options={CurrencyJson}
                      placeholder="Currency"
                      classNamePrefix={"select-opt"}
                      isSearchable={false}
                      value={currencyOption}
                      onChange={(event => HandleMultiSelect(event, 'currency'))}
                    />
                  </div>
                </Col>
                <Col>
                  <label>Price </label>
                  <CurrencyFormat
                    displayType={'input'} thousandSeparator={true}
                    name="price"
                    value={price}
                    placeholder="Enter price here"
                    className="form-control"
                    onValueChange={(values) => DecodePriceValue(values)}
                    onChange={(event) => HandleChangeFunc(event)}
                  />
                </Col>
                <Col>
                  <div className="custom-selectbox-form selectbox-dropdown">
                    <label></label>
                    <Select
                      name="price_qualifier"
                      options={[
                        { value: "", label: "Select option" },
                        { value: "Per week", label: "Per week" },
                        { value: "Per month", label: "Per month" },
                        { value: "Per annum", label: "Per annum" }
                      ]}
                      placeholder="Price qualifier"
                      classNamePrefix={"select-opt"}
                      isSearchable={false}
                      value={priceQualifier}
                      onChange={(event) => HandleMultiSelect(event, 'price_qualifier')}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={6} className="mb-4">
            <div className="form-check dashboard-checkbox me-3 mt-4">
              <input className="form-check-input" type="checkbox"
                value={true}
                id="vat"
                name="exclusive_of_vat"
                onChange={(event) => HandleCheckBoxValue(event)}
                checked={checkboxValues?.['exclusive_of_vat']} />
              <label className="form-check-label" for="vat">Exclusive of Vat</label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <Row>
                <Col lg={3}>
                  <label>Currency</label>
                  <div className="custom-selectbox-form selectbox-dropdown">
                    <Select
                      name="currency"
                      options={CurrencyJson}
                      placeholder="Currency"
                      classNamePrefix={"select-opt"}
                      isSearchable={false}
                      value={currencyPricePerSqftOption}
                      onChange={(event => HandleMultiSelect(event, 'rent.price_per_sqft_currency'))}
                    />
                  </div>
                </Col>
                <Col>
                  <label>Price per Sqft</label>
                  <input type="text" className={`form-control${formError?.["rent.price_per_sqft"]?.hasError ? ' pu-has-control-error' : ''}`}
                    placeholder="Enter price per sqft here"
                    name="rent.price_per_sqft"
                    defaultValue={props.editItem?.rent?.price_per_sqft}
                    onChange={(event) => HandleChangeFunc(event)} />
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <Row>
                <Col lg={3}>
                  <label>Currency</label>
                  <div className="custom-selectbox-form selectbox-dropdown">
                    <Select
                      name="currency"
                      options={CurrencyJson}
                      placeholder="Currency"
                      classNamePrefix={"select-opt"}
                      isSearchable={false}
                      value={currencyServiceChargeOption}
                      onChange={(event => HandleMultiSelect(event, 'service_charge_currency'))}
                    />
                  </div>
                </Col>
                <Col>
                  <label>Service Charge</label>
                  <input type="text" className="form-control" placeholder="Enter service charge here"
                    name="service_charge"
                    defaultValue={props.editItem.service_charge} />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <Row>
                <Col lg={3}>
                  <label>Currency</label>
                  <div className="custom-selectbox-form selectbox-dropdown">
                    <Select
                      name="business_rate_currency"
                      options={CurrencyJson}
                      placeholder="Currency"
                      classNamePrefix={"select-opt"}
                      isSearchable={false}
                      value={currencyBusinessRateOption}
                      onChange={(event => HandleMultiSelect(event, 'business_rate_currency'))}
                    />
                  </div>
                </Col>
                <Col>
                  <label>Business Rates</label>
                  <input type="text" className="form-control" placeholder="Enter business rates here"
                    name="business_rates" defaultValue={props.editItem.business_rates} />
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Interior</label>
              <input type="text" className="form-control" placeholder="Enter interior here"
                name="interior"
                defaultValue={props.editItem.interior} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Facilities</label>
              <div className="custom-selectbox-form selectbox-dropdown">
                <Select
                  name="amenities"
                  isMulti={true}
                  options={facilities}
                  placeholder="Please select facilities"
                  classNamePrefix={"select-opt"}
                  isSearchable={true}
                  onChange={event => HandleMultiSelect(event, 'amenities', true)}
                  value={selFacilities}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={10} className="mb-4">
            <div className="form-wrapper">
              <label>Highlight Features</label>
            </div>
            <div className="d-flex justify-content-start btn-wrapper">
              <div className="mb-0 w-100">
                {
                  highLightFeatures?.map((element, i) => (
                    <div key={i} className="mb-4 d-flex justify-content-start">
                      <div className="form-wrapper w-100">
                        <input type="text" className={`form-control`} autoFocus={i > 0}
                          name={`special[${i}]`}
                          onChange={(event) => handleHighLightFeaturesChange(event, i)}
                          placeholder="Enter highlight feature"
                          onKeyPress={(event) => handleHighLightFeaturesKP(event, i)}
                          value={element} />
                      </div>
                      <div className="d-flex justify-content-between btn-add-delete">
                        {(highLightFeatures.length === (i + 1)) &&
                          <button type="button" className="btn-none" onClick={() => addHighLightFeatures(i)}>
                            <i className="icon icon-add-circle"></i>
                          </button>
                        }
                        {highLightFeatures.length > 1 &&
                          <button type="button" className="btn-none" onClick={() => removeHighLightFeatures(i)}>
                            <i className="icon icon-delete-circle"></i>
                          </button>
                        }
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Viewing</label>
              <input type="text" className="form-control" placeholder="Enter viewing here"
                name="viewing"
                defaultValue={props.editItem.viewing} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={10}>
            <div className="form-wrapper">
              <label>Agent Notes</label>
            </div>
            <div className="d-flex justify-content-start btn-wrapper">
              {/* {agentNotes} */}
              <div className="mb-0 w-100">
                {
                  agentNotes.map((element, i) => (
                    <div key={i} className="mb-4 d-flex justify-content-start">
                      <div className="form-wrapper w-100">
                        <input type="text" className={`form-control`} autoFocus={i > 0}
                          name={`agent_notes[${i}]`}
                          onChange={(event) => handleAgentNotesChange(event, i)}
                          placeholder="Enter agent notes here"
                          onKeyPress={(event) => handleAgentNotesKP(event, i)}
                          value={element} />
                      </div>
                      <div className="d-flex justify-content-between btn-add-delete">
                        <button type="button" className="btn-none" onClick={() => addAgentNotes(i)}>
                          <i className="icon icon-add-circle"></i>
                        </button>
                        {i > 0 &&
                          <button type="button" className="btn-none" onClick={() => removeAgentNotes(i)}>
                            <i className="icon icon-delete-circle"></i>
                          </button>
                        }
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Video Url</label>
              <input type="text" name="video_tour.url" className="form-control"
                defaultValue={props.editItem.video_tour?.url}
                placeholder="Enter video url here" />
            </div>
          </Col>
          <Col lg={6} className="mb-4">
            <div className="form-wrapper">
              <label>Virtual Tour Url</label>
              <input type="text" name="virtual_tour.url" className="form-control"
                defaultValue={props.editItem.virtual_tour?.url}
                placeholder="Enter virtual tour url here" />
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <p>Drag and drop assets - Property images. The order of the assets below is the order they will be shown on the website.</p>
          <div className="text-end">
            <Button className="btn-primary-dashboard" onClick={() => showModalImagesFunc()}>Add</Button>
          </div>
        </div>
        {/* ModalPopUp */}
        {
          showModalImages && <ModalPopUp
            modalShow={showModalImages}
            getUploadedData={getUploadedData}
            modalAction={'upload'}
            setModalShowFunc={setShowModalImagesFunc}
            uploadedDataFieldName="images"
            parentPath="commercial-properties"
            format_text=".png, .jpg, .jpeg, .webp"
            accept_format={{
              'image/jpeg': ['.jpeg', '.png', '.jpg', '.webp']
            }}
          />
        }
        {/* UploadedItemsTable */}
        <StatcTable
          menu="Commercial Properties"
          parentPath="file"
          allItems={allUploadedItems.filter(item => (item.field === 'images'))}
          getUploadedData={getUploadedData}
        />
        <Row>
          <Col lg={5} className="mb-4">
            <div className="form-wrapper">
              <label>Floorplan</label>
              <input type="file" className="form-control" placeholder=""
                name="floorplans" onChange={(event) => GetUploadedUrl(event, 'custom-file1')} id="custom-file1" />
            </div>
            {
              floorPlanUrl &&
              <a target="_blank" href={floorPlanUrl}><p className="brochure-url">{floorPlanUrl}</p></a>
            }
          </Col>
          <Col lg={1} className="mb-4">
            <label> </label>
            <Button className="btn btn-default" onClick={() => {
              ClearAttachmentField('custom-file1');
              setFloorPlanUrl('');
              set(DefaultFields, 'floorplans', {});
            }}>Clear</Button>
          </Col>
        </Row>
        <FormFooter parentPath={props.parentPath}></FormFooter>
      </Form>
    </>
  )
}

const EditCommercialProperty = (props) => {
  return <CommonEditItemComp PuFormControlsEdit={
    (HandleSubmitFunc, SetPublishActionAvailability, item) => {
      return (
        <>
          <EditFormControls
            HandleSubmitFunc={HandleSubmitFunc}
            SetPublishActionAvailability={SetPublishActionAvailability}
            editItem={item}
            {...props}
          />
        </>
      )
    }
  } {...props} />
}

export default EditCommercialProperty;
